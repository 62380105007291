/* eslint-disable @typescript-eslint/camelcase */
import { ISandbox } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';

import { get, post } from '../../api/utils';

export interface ISandboxProps extends ISandbox {
    csrfToken: string;
    sandboxesUri: string;
    deleteSandbox: Function;
}

export default function Sandbox(props: ISandboxProps): ReactElement {
    const { csrfToken, sandboxesUri, deleteSandbox, ...sandbox } = props;
    const [sandboxLocalState, setSandboxLocalState] = useState<ISandbox>(
        sandbox,
    );
    const [sshConfig, setSshConfig] = useState(null);
    const [loading, setLoading] = useState<string | undefined>(undefined);

    useEffect(() => {
        const monitorSandboxSpawning = async (): Promise<void> => {
            if (sandboxLocalState.arn === '') {
                return;
            }

            try {
                const {
                    data: monitoredSandbox,
                    success,
                }: { data: ISandbox; success: boolean } = await get(
                    `${sandboxesUri}/${encodeURIComponent(
                        sandboxLocalState.arn,
                    )}.json`,
                    csrfToken,
                );

                if (success === false) {
                    alert(
                        'Error while monitoring the sandbox. Please refresh this page.',
                    );
                    setNeedRefresh(false);
                    return;
                }

                setSandboxLocalState(monitoredSandbox);
            } catch (err) {
                console.log(err);
                setNeedRefresh(false);
            }
        };

        if (sandboxLocalState.status === 'RUNNING') {
            setLoading(undefined);

            return;
        }

        if (sandboxLocalState.status === 'DESTROYING') {
            setLoading('all');

            return;
        }

        setLoading('all');
        monitorSandboxSpawning();
    }, [sandboxLocalState]);

    const wakeSandbox = async (): Promise<void> => {
        setLoading('wake');
        try {
            const {data, status}: {data: number, status: boolean} = await post(
                sandboxLocalState.renew_autostop_uri,
                csrfToken,
                {},
            );
            
            if (status === false) {
                alert('Error while waking up the sandbox. Try again later.');
                return;
            }

            setSandboxLocalState({ ...sandboxLocalState, auto_stop_in_mins: data });
        } catch (err) {
            console.error(err);
            alert('Error while waking the sandbox. Try again later.');
        } finally {
            setLoading(undefined);
        }
    };

    const onClickDeleteSandbox = async (): Promise<void> => {
        setLoading('delete');
        setSandboxLocalState({ ...sandboxLocalState, status: 'DESTROYING' });

        try {
            await deleteSandbox(sandboxLocalState);
        } catch (error) {
            alert(error);
            setLoading(undefined);
        }
    };

    const getSshConfig = async (): Promise<void> => {
        setLoading('ssh');
        try {
            const resp = await get(sandbox.ssh_uri, csrfToken);
            setSshConfig(resp.ssh_config);
        } catch (err) {
            throw err;
        } finally {
            setLoading(undefined);
        }
    };

    if (sandboxLocalState.status === 'CREATING') {
        return (
            <div className="panel panel-default">
                <div
                    className="panel-body d-flex flex-column justify-content-between"
                    style={{ minHeight: '135px' }}
                >
                    <h3 className="mt-0">
                        <i className="fa fa-terminal"></i>
                        <span className="ml-2">
                            Creating sandbox {sandboxLocalState.image.name}...
                        </span>
                    </h3>
                    <div
                        // className="align-self-center"
                        style={{ alignSelf: 'center' }}
                    >
                        <i className="fa fa-solid fa-spinner fa-spin fa-3x"></i>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="panel panel-default">
            <div
                className="panel-body d-flex flex-column justify-content-between"
                style={{ minHeight: '135px' }}
            >
                <h3 className="mt-0">
                    <i className="fa fa-terminal"></i>
                    <span className="ml-2">
                        {sandboxLocalState.aws_region} -{' '}
                        {sandboxLocalState.task}
                    </span>
                    <div className="pull-right">
                        <span
                            className={`fs-4 label ml-2 label-${
                                sandboxLocalState.status === 'RUNNING'
                                    ? 'success'
                                    : sandboxLocalState.status === 'DESTROYING'
                                    ? 'danger'
                                    : 'warning'
                            }`}
                        >
                            {sandboxLocalState.status}
                        </span>
                        <span
                            className={`fs-4 label ml-2 label-${
                                sandboxLocalState.auto_stop_in_mins > 30
                                    ? 'info'
                                    : 'warning'
                            }`}
                        >
                            {sandboxLocalState.auto_stop_in_mins} minutes
                            remaining
                        </span>
                    </div>
                </h3>
                <div className="mt-2">
                    <div>
                        {sandboxLocalState.url_vscode && (
                            <a
                                className={`btn btn-default ${
                                    loading !== undefined ? 'disabled' : ''
                                }`}
                                href={sandboxLocalState.url_vscode}
                                rel="noreferrer"
                                tabIndex={loading !== undefined ? 0 : -1}
                                target="_blank"
                            >
                                VSCode
                            </a>
                        )}
                        <a
                            className={`ml-2 btn btn-default ${
                                loading !== undefined ? 'disabled' : ''
                            }`}
                            href="#"
                            onClick={getSshConfig}
                            tabIndex={loading !== undefined ? 0 : -1}
                        >
                            SSH
                        </a>
                        <a
                            className={`ml-2 btn btn-default ${
                                loading !== undefined ? 'disabled' : ''
                            }`}
                            href={sandboxLocalState.url_ttyd}
                            rel="noreferrer"
                            tabIndex={loading !== undefined ? 0 : -1}
                            target="_blank"
                        >
                            TTYD
                        </a>
                        <a
                            className={`ml-2 btn btn-default ${
                                loading !== undefined ? 'disabled' : ''
                            }`}
                            href={sandboxLocalState.web.replace('PORT', '80')}
                            rel="noreferrer"
                            tabIndex={loading !== undefined ? 0 : -1}
                            target="_blank"
                        >
                            HTTP - Port 80
                        </a>
                        <div className="pull-right">
                            <button
                                className="btn btn-success"
                                disabled={loading !== undefined}
                                onClick={(): void => void wakeSandbox()}
                                style={{ minWidth: '150px' }}
                            >
                                {['wake', 'all'].includes(loading) ? (
                                    <i className="fa fa-solid fa-spinner fa-spin"></i>
                                ) : (
                                    'Add more time'
                                )}
                            </button>
                            <button
                                className="ml-2 btn btn-danger"
                                disabled={loading !== undefined}
                                onClick={onClickDeleteSandbox}
                                style={{ minWidth: '150px' }}
                            >
                                {['delete', 'all'].includes(loading) ? (
                                    <i className="fa fa-solid fa-spinner fa-spin"></i>
                                ) : (
                                    'Delete sandbox'
                                )}
                            </button>
                        </div>
                    </div>
                    {loading === 'ssh' ? (
                        <div className="mt-2">Generating ssh config...</div>
                    ) : (
                        sshConfig !== null && (
                            <div className="mt-2">
                                <div>SSH configuration:</div>
                                <pre>
                                    $> ssh -p {sshConfig.bind_port} root@
                                    {sshConfig.ssh}
                                    <br />
                                    Password: {sandboxLocalState.tkn}
                                </pre>
                            </div>
                        )
                    )}
                    {isSafari && (
                        <div className="mt-2">
                            TTY credentials:
                            <pre>
                                Login:{' '}
                                {
                                    sandboxLocalState.url_ttyd.match(
                                        /.*\/([^\/:]*):/,
                                    )[1]
                                }
                                <br />
                                Password:{' '}
                                {
                                    sandboxLocalState.url_ttyd.match(
                                        /.*:(.*)@.*/,
                                    )[1]
                                }
                            </pre>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
