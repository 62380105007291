import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, ReactElement, useState } from 'react';

import { patch } from '../../../../api/utils';
import ErrorInline from '../../../common/ErrorInline';
import { IAction, IState, SetLoading, UpdateStartEnd } from '../reducer';

interface IProps {
    dispatch: (action: IAction) => void;
    state: IState;
}

const isValid = (dateStr: string): boolean => moment(dateStr).isValid();

export default function StartEnd({ dispatch, state }: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [end, setEnd] = useState<string>(state.endDate);
    const [start, setStart] = useState<string>(state.startDate);

    const onChangeEnd = (e: ChangeEvent<HTMLInputElement>): void => {
        setEnd(e.target.value);
    };

    const onChangeStart = (e: ChangeEvent<HTMLInputElement>): void => {
        setStart(e.target.value);
    };

    const onSubmit = async (): Promise<void> => {
        if (!isValid(start) || !isValid(end)) {
            return;
        }
        setError(null);
        dispatch(new SetLoading(true));

        try {
            await patch(state.updateBatchCurriculumURI, state.csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                batch_curriculum: {
                    end_date: end,
                    start_date: start,
                },
                /* eslint-enable @typescript-eslint/camelcase */
            });
            dispatch(new UpdateStartEnd(start, end));
        } catch (err) {
            setError(err.message);
        }

        dispatch(new SetLoading(false));
    };

    return (
        <div className="start-end-container">
            <div className={`field${isValid(start) ? '' : '_with_errors'}`}>
                <label htmlFor="planner-start-date-input">Start date</label>
                <input
                    className="form-control"
                    disabled={state.loading}
                    id="planner-start-date-input"
                    onBlur={onSubmit}
                    onChange={onChangeStart}
                    type="date"
                    value={start}
                />
            </div>

            <div
                className={`field${isValid(end) ? '' : '_with_errors'} sm-gap`}
            >
                <label htmlFor="planner-end-date-input">End date</label>
                <input
                    className="form-control"
                    disabled={state.loading}
                    id="planner-end-date-input"
                    onBlur={onSubmit}
                    onChange={onChangeEnd}
                    type="date"
                    value={end}
                />
            </div>

            {error && <ErrorInline error={error} />}
        </div>
    );
}
