import {
    IProjectNextProjectDetails,
    IProjectProgress,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';
import ProgressBar from '../common/ProgressBar';

import ProjectReadyForReviewBody, {
    IProjectPeerReview,
} from './ProjectReadyForReviewBody';

interface IProps {
    csrfToken: string;
    fullManualReview: boolean;
    loading: boolean;
    nextProjectDetails: IProjectNextProjectDetails | null;
    onStartReview: () => void;
    peerReview: IProjectPeerReview | null;
    progress: IProjectProgress;
    skipURI: string;
}

export default function ProjectReviewBody({
    csrfToken,
    fullManualReview,
    loading,
    nextProjectDetails,
    onStartReview,
    peerReview,
    progress,
    skipURI,
}: IProps): ReactElement {
    /* eslint-disable @typescript-eslint/camelcase */
    const {
        auto_review: {
            can_execute: canExecute,
            completion,
            inability_to_execute_reason: reason,
        },
        can_skip: canSkip,
        can_start_peer_review: canStartPeerReview,
    } = progress;
    /* eslint-enable @typescript-eslint/camelcase */

    // Message
    const hasDoneMessage =
        (reason === 'done' || reason === 'full_peer_review') &&
        nextProjectDetails.message;
    const hasInProgressMessage = reason === 'in_progress';
    const hasManualMessage = reason === 'manual';
    const hasMessage =
        hasDoneMessage || hasInProgressMessage || hasManualMessage;

    // CTA
    const hasGoToHomeCTA =
        reason === 'done' &&
        (nextProjectDetails.curriculum_completed || !nextProjectDetails.uri);
    const hasLaunchProjectReviewCTA = canExecute;
    const hasOpenNextProjectCTA =
        (canStartPeerReview ||
            reason === 'done' ||
            reason === 'full_peer_review') &&
        !hasInProgressMessage &&
        nextProjectDetails.uri;
    const hasSkipCTA = false; // canSkip && !hasOpenNextProjectCTA;
    const hasStartPeerReviewCTA = canStartPeerReview && !hasInProgressMessage;

    const hasCTA =
        hasGoToHomeCTA ||
        hasLaunchProjectReviewCTA ||
        hasOpenNextProjectCTA ||
        hasSkipCTA;

    return (
        <>
            {peerReview && hasStartPeerReviewCTA && (
                <ProjectReadyForReviewBody
                    csrfToken={csrfToken}
                    peerReview={peerReview}
                />
            )}

            {hasMessage && (
                <div>
                    {hasDoneMessage && <p>{nextProjectDetails.message}</p>}

                    {hasInProgressMessage && (
                        <>
                            <p>
                                <Icon
                                    name="refresh"
                                    spin={true}
                                    text="Processing... Please wait..."
                                />
                            </p>

                            {completion && (
                                <div className="mt-2">
                                    <ProgressBar
                                        value={completion.percentage}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    {hasManualMessage && (
                        <p>
                            Please review all the <strong>manual checks</strong>{' '}
                            before you launch the project review.
                        </p>
                    )}
                </div>
            )}

            {!hasMessage && !hasStartPeerReviewCTA && (
                <p>
                    Please review <strong>all the tasks</strong> before you
                    start the peer review.
                </p>
            )}

            {hasCTA && (
                <div className="d-flex flex-wrap gap-3 justify-content-between mt-4">
                    <div className="btn-group">
                        {hasGoToHomeCTA && (
                            <a className="btn btn-primary" href="/">
                                <Icon name="home" text="Go to home" />
                            </a>
                        )}

                        {hasLaunchProjectReviewCTA && (
                            <button
                                className="btn btn-primary"
                                disabled={loading}
                                id="project-launch-review-btn"
                                onClick={onStartReview}
                            >
                                <Icon
                                    name={loading ? 'refresh' : 'paper-plane'}
                                    spin={loading}
                                    text={
                                        loading
                                            ? fullManualReview
                                                ? 'Preparing your manual review'
                                                : 'Reviewing all the tasks'
                                            : fullManualReview
                                            ? 'Ready for my review'
                                            : 'Review all the tasks'
                                    }
                                />
                            </button>
                        )}

                        {hasOpenNextProjectCTA && (
                            <a
                                className="btn btn-primary"
                                href={nextProjectDetails.uri}
                            >
                                <Icon
                                    name="code-fork"
                                    text="Open the next project"
                                />
                            </a>
                        )}
                    </div>
                    <div>
                        {hasSkipCTA && (
                            <form action={skipURI} method="post">
                                <input
                                    name="authenticity_token"
                                    type="hidden"
                                    value={csrfToken}
                                />

                                <button
                                    className="btn btn-default"
                                    type="submit"
                                >
                                    <Icon
                                        name="forward"
                                        text="Skip this project"
                                    />
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
