import { Moment } from 'moment';

export function capitalize(value: string): string {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

export function dig<T, Res>(obj: T, key: string): Res {
    return key.split('.').reduce((o, k) => o[k], obj);
}

export function getTimeZone(moment: Moment): string {
    const longName = new Intl.DateTimeFormat(undefined, {
        timeZoneName: 'long',
    })
        .formatToParts(moment.toDate())
        .find((f) => f.type === 'timeZoneName').value;

    return [longName, `(${moment.format('ZZ')})`].join(' ');
}

export function isURL(str: string): boolean {
    let url;

    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}

interface IPluralizeOptions {
    strOnly?: boolean;
    suffix?: string;
}

const DEFAULT_PLURALIZE_OPTIONS: IPluralizeOptions = {
    strOnly: false,
    suffix: 's',
};

export function pluralize(
    count: number,
    str: string,
    options?: IPluralizeOptions,
): string {
    const { strOnly, suffix } = { ...DEFAULT_PLURALIZE_OPTIONS, ...options };
    let actualStr = str;
    let actualSuffix = suffix;
    let result = '';

    if (str.endsWith('x')) {
        actualSuffix = 'es';
        // If a noun ends in a y that is preceded by a consonnant, the plural is formed by ies.
        // Else it's just adding s.
    } else if (/[^aeiou]y$/i.test(str)) {
        actualStr = str.slice(0, -1);
        actualSuffix = 'ies';
    }

    if (!strOnly) {
        result += `${new Intl.NumberFormat().format(count)} `;
    }

    return result + `${actualStr}${count === 1 ? '' : actualSuffix}`;
}
