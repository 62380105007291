import { ICurriculum } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { get } from '../../api/utils';
import Icon from '../common/Icon';

interface ICurriculumMappedProps {
    curriculumDestination: ICurriculum;
    curriculumsForMappingTo: ICurriculum[];
    getCurriculumsCompatibiltyURI: string;
    csrfToken: string;
    setCurriculumMapped: (
        curriculumDestinationId: number,
        curriculumSourceId: number,
    ) => void;
}

interface IFetchCurriculumCompatibiltyData {
    percentage: number;
    comments: string[];
}

export default function CurriculumMappingOptions(
    data: ICurriculumMappedProps,
): ReactElement {
    const {
        curriculumDestination,
        curriculumsForMappingTo,
        getCurriculumsCompatibiltyURI,
        csrfToken,
        setCurriculumMapped,
    } = data;
    const [mappedCurriculum, setMappedCurriculum] = useState<string | ''>('');
    const [label, setLabel] = useState<string>('label-default');
    const [score, setScore] = useState<number | undefined>(undefined);
    const [comments, setComments] = useState<string[]>([
        'Please select a destination curriculum to map.',
    ]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchCurriculumCompatibilty = async (
        curriculumSourceId: string,
        curriculumDestinationId: string,
    ): Promise<void> => {
        if (curriculumSourceId === '0') {
            setComments(['The student will be added to the curriculum.']);
            setLabel('label-success');
            setCurriculumMapped(
                parseInt(curriculumDestinationId),
                parseInt(curriculumSourceId),
            );
            return;
        }
        if (curriculumSourceId === '-1') {
            setComments(['The student will NOT be added to the curriculum.']);
            setLabel('label-success');
            setCurriculumMapped(
                parseInt(curriculumDestinationId),
                parseInt(curriculumSourceId),
            );
            return;
        }
        setLoading(true);

        try {
            const fetchCurriculumCompatibiltyData = await get<
                IFetchCurriculumCompatibiltyData
            >(
                getCurriculumsCompatibiltyURI
                    .replace('$1', curriculumSourceId)
                    .replace('$2', curriculumDestinationId),
                csrfToken,
            );
            switch (fetchCurriculumCompatibiltyData.percentage) {
                case 0:
                    setLabel('label-danger');
                    break;
                case 100:
                    setLabel('label-success');
                    break;
                default:
                    setLabel('label-warning');
                    break;
            }
            setScore(fetchCurriculumCompatibiltyData.percentage);
            setComments(fetchCurriculumCompatibiltyData.comments);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log({ err });
            alert(err);
        } finally {
            setLoading(false);
            setCurriculumMapped(
                parseInt(curriculumDestinationId),
                parseInt(curriculumSourceId),
            );
        }
    };

    const handleOnChangeCurriculumMappingOption = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ): void => {
        setMappedCurriculum(event.target.value);

        fetchCurriculumCompatibilty(
            event.target.value,
            curriculumDestination.id.toString(),
        );
    };

    return (
        <div
            className="well-sm panel panel-default"
            style={{ minWidth: '100%' }}
        >
            <div
                className={`panel-body ${label}`}
                style={{ minHeight: '120px' }}
            >
                <div className="row">
                    <div className="col-xs-12 col-md-4">
                        <span>
                            Mapping to the curriculum DESTINATION:{' '}
                            <b>{curriculumDestination.name}</b>
                        </span>
                        <select
                            className="form-control"
                            disabled={loading}
                            name={`select_curriculum_mapping_options_${curriculumDestination.id}`}
                            onChange={handleOnChangeCurriculumMappingOption}
                            value={mappedCurriculum}
                        >
                            <option disabled value="">
                                Select a curriculum source
                            </option>
                            <option key={-1} value="-1">
                                Don't add the student to the curriculum.
                            </option>
                            <option key={0} value="0">
                                Add the student to the curriculum.
                            </option>
                            {curriculumsForMappingTo.map((curriculumSource) => (
                                <option
                                    key={curriculumSource.id}
                                    value={curriculumSource.id}
                                >
                                    {curriculumSource.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-xs-12 col-md-8 text-center">
                        {loading ? (
                            <Icon name="circle-o-notch" size="4x" spin />
                        ) : (
                            <>
                                {score !== undefined && (
                                    <span>
                                        Matching pourcentage: <b>{score}%</b>
                                    </span>
                                )}
                                <div>
                                    <ul className="text-left">
                                        {comments.map((comment, idx) => (
                                            <li key={idx}>{comment}</li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
