import {
    IUserCurriculumStatuses,
    UserCurriculumStatusesStatusesType,
    // UserCurriculumStatusesStatuses,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { buildStyles } from 'react-circular-progressbar';

import { post } from '../../api/utils';
import CircleProfilePicture from '../common/CircleProfilePicture';
import Icon from '../common/Icon';

enum UserCurriculumStatusesStatusesEnum {
    noAccess = 'No access',
    inProgress = 'In progress',
    notValidated = 'Not validated',
    validated = 'Validated',
    transferredFrom = 'Transferred from',
    transferred = 'Transferred',
}

interface IMarkStudentRowProps {
    ucs: IUserCurriculumStatuses;
    key: string;
    markStudentsUpdateURI: string;
    csrfToken: string;
    setUcss: React.Dispatch<React.SetStateAction<IUserCurriculumStatuses[]>>;
}

function MarkStudentRow(props: IMarkStudentRowProps): ReactElement {
    const { ucs, markStudentsUpdateURI, csrfToken, setUcss } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const saveNewUcsStatus = async (
        ucsId: string,
        newUcsStatus: UserCurriculumStatusesStatusesType,
    ): Promise<void> => {
        setLoading(true);

        try {
            await post<IUserCurriculumStatuses>(
                markStudentsUpdateURI.replace(
                    ':user_curriculum_status_id',
                    ucsId,
                ),
                csrfToken,
                {
                    newStatus: newUcsStatus,
                },
            );
            setUcss((oldUcss) =>
                oldUcss.map((ucs) => {
                    if (ucs.id === ucsId) {
                        ucs.status = newUcsStatus;
                    }
                    return ucs;
                }),
            );
        } catch (err) {
            alert('An error occured, please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const renderStatus = (
        status: UserCurriculumStatusesStatusesType,
    ): ReactElement => {
        switch (status) {
            case UserCurriculumStatusesStatusesEnum.inProgress:
                return (
                    <span className="label label-default p-2">
                        {UserCurriculumStatusesStatusesEnum.inProgress}
                    </span>
                );
            case UserCurriculumStatusesStatusesEnum.validated:
                return (
                    <span className="label label-success p-2">
                        {UserCurriculumStatusesStatusesEnum.validated}
                    </span>
                );
            case UserCurriculumStatusesStatusesEnum.notValidated:
                return (
                    <span className="label label-danger p-2">
                        {UserCurriculumStatusesStatusesEnum.notValidated}
                    </span>
                );
            default:
                return (
                    <span className="label label-info p-2">
                        {status.replace('_', ' ')}
                    </span>
                );
        }
    };

    const renderActionButton = React.useCallback(
        (currentUCS: IUserCurriculumStatuses) => {
            if (loading) {
                return (
                    <button
                        className="btn btn-default btn-sm"
                        disabled
                        style={{ minWidth: '42%' }}
                    >
                        <Icon name="spinner" spin />
                    </button>
                );
            }
            if (
                [
                    UserCurriculumStatusesStatusesEnum.transferred,
                    UserCurriculumStatusesStatusesEnum.transferredFrom,
                    UserCurriculumStatusesStatusesEnum.noAccess,
                ].includes(
                    currentUCS.status as UserCurriculumStatusesStatusesEnum,
                )
            ) {
                return (
                    <span className="label label-warning p-2">
                        You can't change this status.
                    </span>
                );
            }
            return (
                <select
                    className="btn btn-default btn-sm"
                    defaultValue={''}
                    onChange={(newStatus): Promise<void> =>
                        saveNewUcsStatus(
                            currentUCS.id,
                            newStatus.target
                                .value as UserCurriculumStatusesStatusesType,
                        )
                    }
                >
                    <option disabled value="">
                        Select a new status
                    </option>

                    <option
                        value={UserCurriculumStatusesStatusesEnum.inProgress}
                    >
                        Mark as in progress
                    </option>

                    <option
                        value={UserCurriculumStatusesStatusesEnum.validated}
                    >
                        Mark as validated
                    </option>

                    <option
                        value={UserCurriculumStatusesStatusesEnum.notValidated}
                    >
                        Mark as not validated
                    </option>
                </select>
            );
        },
        [loading],
    );

    return (
        <tr>
            <td>
                <CircleProfilePicture
                    border={false}
                    size={36}
                    tooltip={false}
                    user={ucs.user}
                />
            </td>
            <td>{ucs.user.full_name}</td>
            <td>{ucs.curriculum.name}</td>
            <td>{ucs.score_mandatory_percentage}%</td>
            <td>{renderStatus(ucs.status)}</td>
            <td>{moment(ucs.end_date).fromNow()}</td>
            <td>{renderActionButton(ucs)}</td>
        </tr>
    );
}

interface IMarkStudentsProps {
    csrfToken: string;
    userCurriculumStatuses: IUserCurriculumStatuses[];
    markStudentsUpdateURI: string;
}

export default function MarkStudents(data: IMarkStudentsProps): ReactElement {
    const { csrfToken, userCurriculumStatuses, markStudentsUpdateURI } = data;

    const [ucss, setUcss] = useState<IUserCurriculumStatuses[]>(
        userCurriculumStatuses,
    );

    return (
        <table className="align-middle table table-striped">
            <thead>
                <tr>
                    <th>Picture</th>
                    <th>Preferred Full Name</th>
                    <th>Curriculum</th>
                    <th>Score mandatory %</th>
                    <th>Status</th>
                    <th>Finished since</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {ucss.map((ucs) => (
                    <MarkStudentRow
                        csrfToken={csrfToken}
                        key={ucs.id}
                        markStudentsUpdateURI={markStudentsUpdateURI}
                        setUcss={setUcss}
                        ucs={ucs}
                    />
                ))}
            </tbody>
        </table>
    );
}
