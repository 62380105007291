import {
    IEventGroup,
    IEventGroupingMode,
    IGroupValidationRules,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';

import { post } from '../../../api/utils';
import ErrorAlert from '../../common/ErrorAlert';
import Icon from '../../common/Icon';

export interface IProps {
    csrfToken: string;
    generateURI: string;
    groupingModes: IEventGroupingMode[];
    groupSizeDefault: number;
    groupValidationRules: IGroupValidationRules;
    mode: IEventGroupingMode;
}

export default function Generate({
    csrfToken,
    generateURI,
    groupingModes,
    groupSizeDefault,
    groupValidationRules,
    mode,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    // generatedGroups is initialized to -1 because manual grouping mode creates zero groups
    const [generatedGroups, setGeneratedGroups] = useState<number>(-1);
    const [groupingMode, setGroupingMode] = useState<IEventGroupingMode>(mode);
    const [groupSize, setGroupSize] = useState<number>(groupSizeDefault);
    const [loading, setLoading] = useState<boolean>(false);
    const [longLoading, setLongLoading] = useState<boolean>(false);
    const longLoadingTimerRef = useRef(null);
    const [onlyAcceptedParticipants, setOnlyAcceptedParticipants] = useState(false);


    const onChangeGroupSize = (e: ChangeEvent<HTMLInputElement>): void => {
        const newGroupSize = parseInt(e.currentTarget.value);
        if (
            Number.isNaN(newGroupSize) ||
            newGroupSize < groupValidationRules.group_size.min
        ) {
            setGroupSize(groupValidationRules.group_size.min);
            return;
        }
        if (newGroupSize > groupValidationRules.group_size.max) {
            setGroupSize(groupValidationRules.group_size.max);
            return;
        }
        setGroupSize(newGroupSize);
    };

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setOnlyAcceptedParticipants(e.currentTarget.checked);
    };

    const onGenerate = async (): Promise<void> => {
        if (loading || longLoading) {
            return;
        }
        setError(null);
        setLoading(true);

        longLoadingTimerRef.current = setTimeout(() => {
            setLongLoading(true);
            setLoading(false);
        }, 2000);

        try {
            const result = await post<IEventGroup[]>(generateURI, csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                generate: {
                    group_size: groupSize,
                    mode: groupingMode.value,
                    only_accepted: onlyAcceptedParticipants
                },
                /* eslint-enable @typescript-eslint/camelcase */
            });
            setGeneratedGroups(result.length);
            window.location.reload();
        } catch (err) {
            setError(err.message);
        }

        clearTimeout(longLoadingTimerRef.current);
        setLoading(false);
        setLongLoading(false);
    };

    useEffect(() => {
        return (): void => clearTimeout(longLoadingTimerRef.current);
    }, []);

    return (
        <>
            {generatedGroups !== -1 ? (
                <div className="alert alert-info mb-0">
                    <strong>Groups generation successful!</strong>
                    <br />
                    {generatedGroups} groups have been generated.
                </div>
            ) : (
                <div className="d-flex flex-column gap-3">
                    <div>
                        <label>Mode</label>
                        <div className="d-flex flex-column gap-3">
                            {groupingModes.map((gMode) => (
                                <label
                                    className="align-items-center d-flex gap-3"
                                    key={gMode.value}
                                >
                                    <input
                                        checked={
                                            gMode.value === groupingMode.value
                                        }
                                        onChange={(): void =>
                                            setGroupingMode(gMode)
                                        }
                                        type="radio"
                                        value={gMode.value}
                                    />
                                    <span style={{ fontSize: '3rem' }}>
                                        <Icon name={gMode.icon} />
                                    </span>
                                    <div className="d-flex flex-column">
                                        <strong>{gMode.label}</strong>
                                        <small className="fw-400 text-muted">
                                            {gMode.description}
                                        </small>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="generate_group_size">Group size</label>
                        <input
                            className="form-control"
                            max={groupValidationRules.group_size.max}
                            min={groupValidationRules.group_size.min}
                            onChange={onChangeGroupSize}
                            type="number"
                            value={groupSize}
                        />
                    </div>

                    <div className="custom-checkbox">
                    <input
                        type="checkbox"
                        id="onlyAcceptedParticipants"
                        checked={onlyAcceptedParticipants}
                        onChange={handleCheckboxChange}
                    />
                    <label
                        for="onlyAcceptedParticipants"
                        className="ml-1">
                        Only people that have accepted the event
                    </label>
                    </div>

                    {longLoading ? (
                        <div className="alert alert-warning mb-0 mt-3">
                            <strong>
                                <Icon fixedWidth name="circle-o-notch" spin />{' '}
                                Generation in progress
                            </strong>
                            <br />
                            Generating groups for more than 500 participants can
                            take up to a few minutes, do not close this tab.
                        </div>
                    ) : (
                        <div className="text-right">
                            <button
                                className="btn btn-primary"
                                disabled={loading || longLoading}
                                onClick={onGenerate}
                            >
                                {loading && (
                                    <Icon
                                        fixedWidth
                                        name="circle-o-notch"
                                        spin
                                    />
                                )}
                                <span>
                                    {loading ? 'Generating...' : 'Generate'}
                                </span>
                            </button>
                        </div>
                    )}

                    {error && (
                        <div className="py-3">
                            <ErrorAlert error={error} noMargin />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
