import {
    IBatchPlanningItem,
    MilestoneType,
    ReleaseScope,
    SessionChangeDirection,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { patch, post } from '../../../../api/utils';
import Icon from '../../../common/Icon';
import { ISession } from '../Projects';

import Session from './Session';

interface IProps {
    csrfToken: string;
    dailyWorkHours: number;
    onUpdate: (response: IBatchPlanningItem[]) => void;
    session: ISession;
}

export default function SessionTimelinePanel({
    csrfToken,
    dailyWorkHours,
    onUpdate,
    session,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);

    const isUnlocked = moment().isAfter(session.startDate, 'days');
    const isCurrent = isUnlocked && !moment().isAfter(session.endDate, 'days');
    const isPast = isUnlocked && !isCurrent;

    const onChangeSession = async (
        bpi: IBatchPlanningItem,
        direction: SessionChangeDirection,
    ): Promise<void> => {
        setError(null);
        try {
            const response = await patch<IBatchPlanningItem[]>(
                bpi.change_session_uri,
                csrfToken,
                { direction },
            );
            onUpdate(response);
        } catch (err) {
            setError(err.message);
        }
    };

    const onSubmitMilestone = async (
        bpi: IBatchPlanningItem,
        type: MilestoneType,
        date: string,
        scope?: ReleaseScope,
    ): Promise<void> => {
        setError(null);
        try {
            const response = await post<IBatchPlanningItem[]>(
                bpi.create_milestone_uri,
                csrfToken,
                {
                    /* eslint-disable @typescript-eslint/camelcase */
                    milestone: {
                        date: date,
                        milestone_type: type,
                        release_scope: scope,
                    },
                    /* eslint-enable @typescript-eslint/camelcase */
                },
            );
            onUpdate(response);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <li style={{ marginBottom: '20px', position: 'relative' }}>
            {session.releaseScope.name === 'everyone' && (
                <div
                    className={`timeline-badge ${
                        isCurrent
                            ? 'text-primary border border-primary'
                            : 'text-info border border-info'
                    }`}
                    style={{
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        fontSize: '1.4em',
                        height: '50px',
                        lineHeight: '50px',
                        marginLeft: '-25px',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '16px',
                        width: '50px',
                    }}
                >
                    <Icon
                        name={
                            isPast ? 'history' : isUnlocked ? 'unlock' : 'lock'
                        }
                    />
                </div>
            )}
            <div
                className={`timeline-panel${isCurrent ? ' current' : ''}${
                    session.releaseScope.name === 'completed' ? ' subpanel' : ''
                }`}
                style={{
                    borderRadius: '2px',
                    marginLeft:
                        session.releaseScope.name === 'completed'
                            ? '100px'
                            : '50px',
                    padding: '20px',
                    position: 'relative',
                    width: '95%',
                }}
            >
                <Session
                    dailyWorkHours={dailyWorkHours}
                    error={error}
                    onChangeSession={onChangeSession}
                    onSubmitMilestone={onSubmitMilestone}
                    session={session}
                />
            </div>
        </li>
    );
}
