/*
 * This component displays a list of checkboxes and labels
 * and add/remove the 'disabled' attribute of the given action button.
 * All checkbox have to be checked to enable the action button.
 *
 * usage in html.erb
 * <%= react_component('common/CheckListGuard', {
 *          checks: [
 *            'Checkbox 1',
 *            'Checkbox 2' ],
 *          actionButtonId: 'the-action-button-id" }) %>
 *
 * <%= f.submit "My action", id: "the-action-button-id" %>
 */
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

interface IProps {
    checks: string[];
    actionButtonId: string;
    customEventName?: string;
}

export default function CheckListGuard({
    checks,
    actionButtonId,
    customEventName = undefined,
}: IProps): ReactElement {
    const [checkStates, setCheckStates] = useState<boolean[]>(
        new Array(checks.length).fill(false),
    );
    const [customEventState, setCustomEventState] = useState<boolean>(true);

    const handleCustomEvent = (event): void => {
        const { status } = event.detail;

        setCustomEventState(status);
    };

    useEffect(() => {
        if (customEventName !== undefined) {
            setCustomEventState(false);
            document.addEventListener(customEventName, handleCustomEvent);

            return (): void => {
                document.removeEventListener(
                    customEventName,
                    handleCustomEvent,
                );
            };
        }
    }, []);

    useEffect(() => {
        if (
            checkStates.filter((check) => check).length === checks.length &&
            customEventState === true
        ) {
            document.getElementById(actionButtonId).removeAttribute('disabled');
        } else {
            document
                .getElementById(actionButtonId)
                .setAttribute('disabled', 'true');
        }
    }, [checkStates, customEventState]);

    return (
        <div>
            {checkStates.map((checked, cIndex) => (
                <div
                    className="form-field"
                    key={`check-${cIndex}`}
                    style={{ listStyle: 'none' }}
                >
                    <label>
                        <input
                            checked={checked}
                            onChange={(): void => {
                                setCheckStates(
                                    checkStates.map((item, index) =>
                                        index === cIndex ? !item : item,
                                    ),
                                );
                            }}
                            style={{ marginRight: '10px' }}
                            type="checkbox"
                        />
                        {checks[cIndex]}
                    </label>
                </div>
            ))}
        </div>
    );
}
