import {
    IEvent,
    IPlanningData,
    IPlanningSession,
    ITimeChartEvent,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, Reducer, useEffect, useReducer } from 'react';

import { get } from '../../../api/utils';
import ErrorAlert from '../../common/ErrorAlert';
import Icon from '../../common/Icon';
import Scheduler, { SchedulerData } from '../../common/scheduler/Scheduler';

import Legend from './Legend';
import EventPreview from './previews/EventPreview';
import SessionPreview from './previews/SessionPreview';
import reducer, {
    ChangeView,
    IAction,
    IState,
    SetData,
    SetError,
    SetPreviewed,
    StartLoading,
} from './reducer';
import { styleAt } from './utils';

interface IProps {
    cohorts: {
        locations: {
            id: number;
            name: string;
        }[];
        title: string;
    }[];
    csrfToken: string;
    dataURI: string;
}

function renderEventPreview(event: IEvent): ReactElement {
    return <EventPreview event={event} />;
}

function renderSessionPreview(
    dailyWorkHours: number,
    session: IPlanningSession,
): ReactElement {
    return <SessionPreview dailyWorkHours={dailyWorkHours} session={session} />;
}

function schedulerData(state: IState): SchedulerData[] {
    const cohorts = state.data.cohorts;
    const items = cohorts.reduce((acc, { locations }, cIdx) => {
        locations.forEach((location, lIdx) => {
            if (state.filters.cohorts[cIdx].locations[lIdx].sessions) {
                location.sessions.forEach((session) => {
                    const id = `session-${cIdx}-${lIdx}-${session.name}`;
                    const isPreviewed = state.preview?.id === id;
                    const style = styleAt(cIdx, cohorts.length, lIdx);

                    /* eslint-disable @typescript-eslint/camelcase */
                    acc.push({
                        className: isPreviewed ? 'border' : undefined,
                        color: style.backgroundColor,
                        end_date: moment(session.ends_at).format(),
                        id,
                        renderPreview: () =>
                            renderSessionPreview(
                                location.daily_work_hours,
                                session,
                            ),
                        start_date: moment(session.starts_at).format(),
                        text: session.name,
                        textColor: style.color,
                        type: 'task',
                    });
                    /* eslint-enable @typescript-eslint/camelcase */
                });
            }

            if (state.filters.cohorts[cIdx].locations[lIdx].timeOffs) {
                location.time_offs.forEach((timeOff) => {
                    const style = styleAt(cIdx, cohorts.length, lIdx, {
                        saturation: 45,
                    });
                    /* eslint-disable @typescript-eslint/camelcase */
                    acc.push({
                        color: style.backgroundColor,
                        end_date: moment(timeOff.end_date)
                            .add(1, 'day')
                            .format(),
                        id: `timeOff-${cIdx}-${lIdx}-${timeOff.id}`,
                        start_date: moment(timeOff.start_date).format(),
                        text: 'Time off',
                        textColor: style.color,
                        type: 'task',
                    });
                    /* eslint-enable @typescript-eslint/camelcase */
                });
            }
        });

        return acc;
    }, []);

    if (state.filters.events) {
        /* eslint-disable @typescript-eslint/camelcase */
        state.data.events.forEach((event) => {
            const id = `event-${event.id}`;
            const isPreviewed = state.preview?.id === id;

            items.push({
                className: isPreviewed ? 'border' : undefined,
                end_date: moment(event.end_at).format(),
                id,
                renderPreview: () => renderEventPreview(event),
                start_date: moment(event.start_at).format(),
                text: event.title,
                textColor: '#292728',
                type: 'task',
            });
        });
        /* eslint-enable @typescript-eslint/camelcase */
    }

    return items;
}

export default function Planning({
    cohorts,
    csrfToken,
    dataURI,
}: IProps): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
        data: {
            cohorts: [],
            events: [],
        },
        error: null,
        filters: {
            all: true,
            cohorts: cohorts.map((c) => ({
                ...c,
                locations: c.locations.map((l) => ({
                    ...l,
                    sessions: true,
                    timeOffs: true,
                })),
            })),
            events: true,
        },
        from: moment()
            .startOf('month')
            .format(),
        loading: false,
        preview: null,
        to: moment()
            .endOf('month')
            .format(),
    });

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            dispatch(new StartLoading());

            try {
                const newData = await get<IPlanningData>(dataURI, csrfToken, {
                    from: state.from,
                    to: state.to,
                });
                dispatch(new SetData(newData));
            } catch (err) {
                dispatch(new SetError(err.message));
            }
        };

        fetchData();
    }, [state.from, state.to]);

    const onViewChange = (mode: string, date: string): void => {
        dispatch(new ChangeView(date, mode));
    };

    const onClick = (event: ITimeChartEvent): void => {
        dispatch(new SetPreviewed(event));
    };

    return (
        <div className="d-flex flex-column gap-3 planning-container">
            {state.error && <ErrorAlert error={state.error} />}

            <div className="d-flex gap-3">
                {(state.data.cohorts.length > 1 ||
                    state.data.cohorts[0]?.locations.length > 1) && (
                    <div style={{ width: 200 }}>
                        <Legend dispatch={dispatch} state={state} />
                    </div>
                )}

                <div className="position-relative" style={{ flex: 1 }}>
                    <Scheduler
                        data={schedulerData(state)}
                        endDate={moment()
                            .add(1, 'month')
                            .format()}
                        onClick={onClick}
                        onViewChange={onViewChange}
                        startDate={moment().format()}
                        views={['day', 'week', 'month']}
                    />

                    {state.loading && (
                        <div
                            className="align-items-center d-flex justify-content-center position-absolute loading-container"
                            style={{
                                backgroundColor: '#fffa',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                top: 0,
                            }}
                        >
                            <Icon name="circle-o-notch" size="4x" spin />
                        </div>
                    )}
                </div>

                {state.preview && (
                    <div style={{ width: 300 }}>
                        <div className="d-flex flex-column gap-5">
                            <div className="align-items-center d-flex gap-5 justify-content-between">
                                <span className="fs-3 fw-600 mb-2 sm-gap">
                                    Preview
                                </span>
                                <Icon
                                    name="times"
                                    onClick={(): void =>
                                        dispatch(new SetPreviewed(null))
                                    }
                                />
                            </div>
                            {state.preview?.renderPreview()}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
