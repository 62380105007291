import { IPlanningData } from 'holberton-school-intranet-api';
import { produce } from 'immer';
import moment, { unitOfTime } from 'moment';

import { SchedulerData } from '../../common/scheduler/Scheduler';

interface IPlanningFilters {
    cohorts: {
        locations: {
            id: number;
            name: string;
            sessions: boolean;
            timeOffs: boolean;
        }[];
        title: string;
    }[];
    events: boolean;
    all: boolean;
}

export interface IState {
    data: IPlanningData;
    error: string | null;
    filters: IPlanningFilters;
    from: string;
    loading: boolean;
    preview: SchedulerData | null;
    to: string;
}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IAction {}
/* eslint-enable @typescript-eslint/no-empty-interface */

export class StartLoading implements IAction {}

export class SetError implements IAction {
    constructor(public error: string) {}
}

export class SetData implements IAction {
    constructor(public data: IPlanningData) {}
}

export class ChangeView implements IAction {
    constructor(public date: string, public mode: string) {}
}

export class ToggleFilter implements IAction {
    constructor(
        public attribute: 'events' | 'sessions' | 'timeOffs' | 'all',
        public cohortIndex?: number,
        public locationIndex?: number,
    ) {}
}

export class SetPreviewed implements IAction {
    constructor(public event: SchedulerData | null) {}
}

export default function reducer(state: IState, action: IAction): IState {
    if (action instanceof StartLoading) {
        return produce(state, (draft) => {
            draft.loading = true;
            draft.error = null;
        });
    }

    if (action instanceof SetError) {
        return produce(state, (draft) => {
            draft.loading = false;
            draft.error = action.error;
        });
    }

    if (action instanceof SetData) {
        return produce(state, (draft) => {
            draft.loading = false;
            draft.data = action.data;
        });
    }

    if (action instanceof ChangeView) {
        return produce(state, (draft) => {
            draft.from = moment(action.date)
                .startOf(action.mode as unitOfTime.StartOf)
                .format();
            draft.to = moment(action.date)
                .endOf(action.mode as unitOfTime.StartOf)
                .format();
        });
    }

    if (action instanceof ToggleFilter) {
        return produce(state, (draft) => {
            const {
                attribute: attr,
                cohortIndex: cIdx,
                locationIndex: lIdx,
            } = action;
            if (attr === 'events') {
                draft.filters.events = !draft.filters.events;
            } else if (attr === 'all') {
                draft.filters.all = !draft.filters.all;
                draft.filters.events = draft.filters.all;

                for (const cohort of draft.filters.cohorts) {
                    for (const location of cohort.locations) {
                        location.sessions = draft.filters.all;
                        location.timeOffs = draft.filters.all;
                    }
                }
            } else {
                const location = draft.filters.cohorts[cIdx].locations[lIdx];
                location[attr] = !location[attr];
            }
        });
    }

    if (action instanceof SetPreviewed) {
        return produce(state, (draft) => {
            draft.preview = action.event;
        });
    }

    return state;
}
