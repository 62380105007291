import { ICurriculum } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { get } from '../../api/utils';
import Icon from '../common/Icon';

import CurriculumMappingOptions from './CurriculumMappingOptions';

interface ICurriculumMapperProps {
    csrfToken: string;
    getCurriculumsCompatibiltyURI: string;
    getCurriculumsForMappingURI: string;
    setDisableCohortSelector: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IGetCurriculumForMappingData {
    curriculums_source: ICurriculum[];
    curriculums_destination: ICurriculum[];
}

interface ICurriculumForMappingData {
    curriculumsSource: ICurriculum[];
    curriculumsDestination: ICurriculum[];
}

interface ICurriculumMapped {
    [key: number]: number;
}

const dispatchCustomEvent = (state: boolean): void => {
    document.dispatchEvent(
        new CustomEvent('user_transfer_target_sSelectorV3_valid', {
            detail: { status: state },
        }),
    );
};

export default function CurriculumMapper(
    data: ICurriculumMapperProps,
): ReactElement {
    const {
        csrfToken,
        getCurriculumsForMappingURI,
        getCurriculumsCompatibiltyURI,
        setDisableCohortSelector,
    } = data;
    const [loading, setLoading] = useState<boolean>(false);
    const [curriculumsForMapping, setCurriculumsForMapping] = useState<
        ICurriculumForMappingData | undefined
    >(undefined);
    const [curriculumMapped, setCurriculumMapped] = useState<
        ICurriculumMapped | undefined
    >(undefined);

    useEffect(() => {
        dispatchCustomEvent(false);
        const fetchGetCurriculumsForMappingData = async (): Promise<void> => {
            try {
                const getCurriculumForMappingData = await get<
                    IGetCurriculumForMappingData
                >(getCurriculumsForMappingURI, csrfToken);
                setCurriculumsForMapping({
                    curriculumsDestination:
                        getCurriculumForMappingData.curriculums_destination,
                    curriculumsSource:
                        getCurriculumForMappingData.curriculums_source,
                });
                setCurriculumMapped(
                    getCurriculumForMappingData.curriculums_source.reduce(
                        (acc, curriculumSource) => {
                            acc[curriculumSource.id] = undefined;
                            return acc;
                        },
                        {},
                    ),
                );
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log({ err });
                alert(err);
            } finally {
                setLoading(false);
            }
        };

        setLoading(true);
        fetchGetCurriculumsForMappingData();
    }, [getCurriculumsForMappingURI]);

    useEffect(() => {
        setDisableCohortSelector(loading);
    }, [loading]);

    useEffect(() => {
        if (curriculumMapped === undefined) {
            dispatchCustomEvent(false);
            return;
        }

        dispatchCustomEvent(true);
    }, [curriculumMapped]);

    const renderMapper = useCallback(() => {
        if (curriculumsForMapping === undefined) {
            return (
                <div className="mt-2 panel-body text-cebter">
                    No curriculums available for mapping.
                </div>
            );
        }

        return (
            <div className="mt-2 panel-body align-items-center d-flex flex-column justify-content-center">
                {curriculumsForMapping.curriculumsDestination.map(
                    (curriculumDestination) => (
                        <CurriculumMappingOptions
                            csrfToken={csrfToken}
                            curriculumDestination={curriculumDestination}
                            curriculumsForMappingTo={
                                curriculumsForMapping.curriculumsSource
                            }
                            getCurriculumsCompatibiltyURI={
                                getCurriculumsCompatibiltyURI
                            }
                            key={curriculumDestination.id}
                            setCurriculumMapped={(
                                curriculumDestinationId: number,
                                curriculumSourceId: number,
                            ): void => {
                                setCurriculumMapped(
                                    (
                                        oldState: ICurriculumMapped,
                                    ): ICurriculumMapped => {
                                        const newState: ICurriculumMapped = {
                                            ...oldState,
                                        };
                                        newState[
                                            curriculumDestinationId
                                        ] = curriculumSourceId;

                                        return newState;
                                    },
                                );
                            }}
                        />
                    ),
                )}
            </div>
        );
    }, [curriculumsForMapping]);

    if (loading) {
        return (
            <div className="mt-2 align-items-center d-flex justify-content-center loading-container">
                <Icon name="circle-o-notch" size="4x" spin />
            </div>
        );
    }

    return (
        <div className="mt-2 panel panel-default">
            <div className="panel-heading">
                <h4 className="panel-title">
                    You are about to map the curriculums between two cohorts.
                </h4>
            </div>
            <div className="panel-body">
                <div className="alert alert-warning">
                    <p>
                        You have to map the curriculums of the{' '}
                        <b>DESTINATION</b> cohort to the <b>SOURCE</b> cohort.
                        You have 3 options:
                    </p>
                    <ul>
                        <li>
                            Map the curriculum to a <b>SOURCE</b> curriculum if
                            the student has already done this curriculum (or a
                            very similar one).
                        </li>
                        <li>
                            Add the student to a new curriculum attached to this{' '}
                            <b>DESTINATION</b> cohort.
                        </li>
                        <li>
                            Do nothing (don't map or add the student to the
                            curriculum).
                        </li>
                    </ul>
                    <p>
                        If a <b>SOURCE</b> curriculum is finished but not
                        validated, it will be automatically marked as
                        "Validated".
                    </p>
                    <p>
                        You can choose to not map the student to a &nbsp;
                        <b>DESTINATION</b>&nbsp;curriculum
                    </p>
                    <p>
                        A matching percentage is displayed to show if the two
                        curriculums are similar. Try to match the curriculums
                        with the highest matching percentage (same name, same
                        id).
                    </p>
                </div>
            </div>
            {renderMapper()}
        </div>
    );
}
