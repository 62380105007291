import { IUserTransferTargetCurriculum } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

export interface IProps {
    targets: IUserTransferTargetCurriculum[];
}

export default function UserTransferTargetSelector({
    targets,
}: IProps): ReactElement {
    return (
        <>
            <div className="field">
                <label htmlFor="select-curriculum">Cohort: </label>
                <select
                    className="form-control"
                    id="select-cohort"
                    name="user_transfer_request[batch_id]"
                    required={true}
                >
                    {targets.map((batch) => (
                        <option
                            key={batch.id.toString()}
                            value={batch.id}
                        >
                             {batch.name} {batch.number ? `(C#${batch.number})` : ''}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}
