import { ICohort } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { get } from '../../api/utils';
import Icon from '../common/Icon';

import CurriculumMapper from './CurriculumMapper';

interface IUserTransferTargetSelectorV3Props {
    userId: number;
    csrfToken: string;
    getCurriculumsCompatibiltyURI: string;
    getCohortsDestinationURI: string;
    getCurriculumsForMappingURI: string;
}

export default function UserTransferTargetSelectorV3(
    data: IUserTransferTargetSelectorV3Props,
): ReactElement {
    const {
        csrfToken,
        getCohortsDestinationURI,
        getCurriculumsForMappingURI,
        getCurriculumsCompatibiltyURI: getCurriculumsCompatibiltyURI,
    } = data;
    const [loading, setLoading] = useState<boolean>(false);
    const [
        disableCohortDestinationSelector,
        setDisableCohortSelector,
    ] = useState<boolean>(false);
    const [cohortDestinationOptions, setCohortDestinationOptions] = useState<
        ICohort[]
    >([]);
    const [cohortDestination, setCohortDestination] = useState<string>('');

    const getCurriculumsForMappingURIMemo = useMemo((): string => {
        if (cohortDestination === '') {
            return null;
        }

        return `${getCurriculumsForMappingURI}${cohortDestination}`;
    }, [cohortDestination]);

    useEffect(() => {
        setLoading(true);
        const fetchGetCohortsDestinationData = async (): Promise<void> => {
            try {
                const getCohortsDestinationData = await get<ICohort[]>(
                    getCohortsDestinationURI,
                    csrfToken,
                );
                setCohortDestinationOptions(getCohortsDestinationData);
            } catch (err) {
                alert({ err });
            } finally {
                setLoading(false);
            }
        };

        fetchGetCohortsDestinationData();
    }, []);

    const handleOnChangeCohortDestination = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ): void => {
        setCohortDestination(event.target.value);
    };

    const renderCohortDestinationSelect = useCallback(
        (cohortDestinationOptions: ICohort[]) => {
            if (loading) {
                return (
                    <div className="align-items-center d-flex justify-content-center loading-container">
                        <Icon name="circle-o-notch" size="4x" spin />
                    </div>
                );
            }

            if (cohortDestinationOptions.length === 0) {
                return <div>No target cohorts found.</div>;
            }

            return (
                <select
                    className="form-control"
                    disabled={disableCohortDestinationSelector}
                    name="select_cohort_destination"
                    onChange={handleOnChangeCohortDestination}
                    value={cohortDestination}
                >
                    <option disabled value="">
                        Select a cohort
                    </option>
                    {cohortDestinationOptions.map((batch) => (
                        <option key={batch.id.toString()} value={batch.id}>
                            {batch.name}
                        </option>
                    ))}
                </select>
            );
        },
        [
            loading,
            disableCohortDestinationSelector,
            cohortDestination,
            cohortDestinationOptions,
        ],
    );

    return (
        <div className="field">
            <label htmlFor="select-curriculum">Destination Cohort: </label>
            {renderCohortDestinationSelect(cohortDestinationOptions)}
            {getCurriculumsForMappingURIMemo !== null && (
                <CurriculumMapper
                    csrfToken={csrfToken}
                    getCurriculumsCompatibiltyURI={
                        getCurriculumsCompatibiltyURI
                    }
                    getCurriculumsForMappingURI={
                        getCurriculumsForMappingURIMemo
                    }
                    setDisableCohortSelector={setDisableCohortSelector}
                />
            )}
        </div>
    );
}
