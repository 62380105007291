import {
    useActionCable,
    useChannel,
} from '@aersoftware/react-use-action-cable';
import { getConfig } from '@rails/actioncable';
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Animated } from 'react-animated-css';
import { Dot } from 'react-animated-dots';

// For after
// createConsumer(getWebSocketURL)

// function getWebSocketURL() {
//   const token = localStorage.get('auth-token')
//   return `wss://example.com/cable?token=${token}`
// }

interface IUserTransferStatus {
    userId: number;
}

export default function UserTransferStatus(
    data: IUserTransferStatus,
): ReactElement {
    const { userId } = data;
    const [progress, setProgress] = useState<
        { current: number; total: number } | undefined
    >(undefined);
    const [progressMessages, setProgressMessages] = useState<string[]>([]);
    const { actionCable } = useActionCable(getConfig('url'));

    const { subscribe, unsubscribe } = useChannel(actionCable, {
        verbose: true,
    });

    useEffect(() => {
        subscribe(
            {
                channel: 'UserTransferStatusChannel',
                // eslint-disable-next-line @typescript-eslint/camelcase
                user_id: userId,
            },
            {
                received(data) {
                    const { progress, message } = data;
                    setProgress(progress);
                    setProgressMessages((oldProgressMessages: string[]) => [
                        ...oldProgressMessages,
                        message,
                    ]);
                },
            },
        );
        return (): void => {
            unsubscribe();
        };
    }, []);

    const displaySuspensedDots = useCallback(
        (index: number): ReactElement => {
            const isLastItem: boolean = index === progressMessages.length - 1;

            if (
                isLastItem === false ||
                progress.current === progress.total ||
                progress.current === -1
            ) {
                return <>.</>;
            }
            return (
                <>
                    <Dot>.</Dot>
                    <Dot>.</Dot>
                    <Dot>.</Dot>
                </>
            );
        },
        [progress, progressMessages],
    );

    const panelStyle = useMemo(() => {
        if (progress?.current === progress?.total) {
            return 'panel-success';
        }
        if (progress?.current === -1) {
            return 'panel-primary';
        }

        return 'panel-info';
    }, [progress]);

    if (progress === undefined) {
        return null;
    }

    return (
        <Animated
            animationIn="bounceIn"
            animationInDuration={500}
            animationOut="bounceOut"
            isVisible={true}
        >
            <div className={`sm-gap panel ${panelStyle}`}>
                <div className="panel-heading">
                    <h3 className="panel-title">
                        Transfer{' '}
                        {progress?.current === -1
                            ? 'stopped !'
                            : progress?.current === progress?.total
                            ? 'done !'
                            : `in progress ${progress?.current} / ${progress?.total}`}
                    </h3>
                </div>
                <div className="panel-body">
                    {progressMessages.map((message: string, index: number) => (
                        <div key={index}>
                            {message}
                            {displaySuspensedDots(index)}
                        </div>
                    ))}
                </div>
            </div>
        </Animated>
    );
}
