/* eslint-disable @typescript-eslint/camelcase */
import { ISandbox, ISandboxImage } from 'holberton-school-intranet-api';
import { groupBy, map, orderBy, random } from 'lodash';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { del, get, post } from '../../api/utils';

import Sandbox from './Sandbox';

export interface IProps {
    images: ISandboxImage[];
    existingSandboxes?: ISandbox[] | undefined;
    sandboxesUri: string;
    csrfToken: string;
    maxNumberOfContainers: number;
}

export default function Sandboxes({
    images,
    existingSandboxes = undefined,
    sandboxesUri,
    csrfToken,
    maxNumberOfContainers,
}: IProps): ReactElement {
    const [sandboxes, setSandboxes] = useState<ISandbox[] | undefined>(
        existingSandboxes,
    );
    const [loading, setLoading] = useState<boolean>(true);
    const imagesByRegion: {
        key: string;
        values: ISandboxImage[];
    } = groupBy(orderBy(images, 'name'), 'aws_region');

    useEffect((): void => {
        const fetchSandboxes = async (): Promise<void> => {
            try {
                const {
                    sandboxes = [],
                    success,
                }: { success: boolean; sandboxes: ISandbox[] } = await get(
                    `${sandboxesUri}.json`,
                    csrfToken,
                    {},
                );

                if (success === false) {
                    alert(data);
                    return;
                }

                setSandboxes(sandboxes);
            } catch (err) {
                console.error(err);
                alert('Error while fetching sandboxes. Try again later.');
            }
        };

        if (sandboxes === undefined) {
            fetchSandboxes();
            return;
        }

        setLoading(false);
    }, [sandboxes]);

    const removeSandbox = (sandbox: ISandbox): void => {
        setSandboxes((oldSandboxesState: ISandbox[]) => {
            return oldSandboxesState.filter(
                (s: ISandbox) => s.id !== sandbox.id,
            );
        });
    };

    const onClickCreateNewSandbox = async (
        image: ISandboxImage,
    ): Promise<void> => {
        const newSandbox: ISandbox & { key: number } = {
            arn: '',
            auto_stop_in_mins: 0,
            aws_region: image.aws_region,
            destroy_uri: '',
            id: random(-1000, -1, false),
            image: image,
            key: random(1000, 1, false),
            renew_autostop_uri: '',
            ssh_uri: '',
            status: 'CREATING',
            task: '',
            tkn: '',
            url_ttyd: '',
            url_vscode: '',
            web: '',
        };

        setSandboxes((oldSanboxes) => [...oldSanboxes, newSandbox]);

        try {
            const {
                data,
                success,
            }: { success: boolean; data: ISandbox } = await post(
                `${sandboxesUri}.json`,
                csrfToken,
                {
                    image_id: image.id,
                },
            );

            if (success === false) {
                alert(data);
                removeSandbox(newSandbox);
                return;
            }

            setSandboxes((oldSanboxes: ISandbox[]) =>
                oldSanboxes.map((s: ISandbox) =>
                    s.id !== newSandbox.id ? s : data,
                ),
            );
        } catch (err) {
            console.error(err);
            removeSandbox(newSandbox);
            alert('Error while creating the sandbox. Try again later.');
        }
    };

    const deleteSandbox = async (sandbox: ISandbox): Promise<void> => {
        const { data, success }: { data: string; success: boolean } = await del(
            sandbox.destroy_uri,
            csrfToken,
        );

        if (success === false) {
            throw new Error(data);
        }
        removeSandbox(sandbox);
    };

    if (loading || sandboxes === undefined) {
        return <div className="mt-2 alert alert-info">Loading...</div>;
    }

    return (
        <>
            <div className="d-flex flex-column">
                <div
                    className="dropdown pull-right"
                    style={{ alignSelf: 'end' }}
                >
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        className="btn btn-lg btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        disabled={sandboxes.length >= maxNumberOfContainers}
                        type="button"
                    >
                        New sandbox <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        {map(
                            imagesByRegion,
                            (
                                availableSandboxes: ISandboxImage[],
                                regionName: string,
                            ) => {
                                return (
                                    <React.Fragment key={regionName}>
                                        <li
                                            className="divider"
                                            role="separator"
                                        ></li>
                                        <li className="dropdown-header">
                                            {regionName}
                                        </li>
                                        {map(
                                            availableSandboxes,
                                            (
                                                availableSandboxe: ISandboxImage,
                                            ) => {
                                                return (
                                                    <li
                                                        key={
                                                            availableSandboxe.id
                                                        }
                                                    >
                                                        <a
                                                            onClick={(): Promise<
                                                                void
                                                            > =>
                                                                onClickCreateNewSandbox(
                                                                    availableSandboxe,
                                                                )
                                                            }
                                                            role="button"
                                                        >
                                                            {
                                                                availableSandboxe.name
                                                            }
                                                        </a>
                                                    </li>
                                                );
                                            },
                                        )}
                                    </React.Fragment>
                                );
                            },
                        )}
                    </ul>
                </div>
                {sandboxes.length === 0 ? (
                    <div className="mt-2 alert alert-info">
                        No sandboxes yet!
                    </div>
                ) : (
                    <div className="mt-2">
                        {sandboxes.map((sandbox) => (
                            <Sandbox
                                csrfToken={csrfToken}
                                deleteSandbox={deleteSandbox}
                                key={sandbox.id}
                                sandboxesUri={sandboxesUri}
                                {...sandbox}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
